import React from 'react';
import handsSvg from 'src/images/Hands_s.svg';

export const DEFAULT_COVER_IMAGE_URL = '/src/images/Hands_s.svg';
const isDefaultImage = (imageUrl: string): boolean => {
  return imageUrl === DEFAULT_COVER_IMAGE_URL;
};

export const coverImageBackgroundStyles = (
  imageUrl: string,
): React.CSSProperties => {
  const backgroundStyles: React.CSSProperties = {};
  if (isDefaultImage(imageUrl)) {
    backgroundStyles.backgroundImage = `url(${handsSvg})`;
    backgroundStyles.backgroundRepeat = 'repeat-x';
    backgroundStyles.backgroundPosition = 'bottom';
  } else {
    backgroundStyles.backgroundImage = `url(${imageUrl})`;
    backgroundStyles.backgroundSize = 'cover';
    backgroundStyles.backgroundPosition = 'center';
    backgroundStyles.backgroundRepeat = 'no-repeat';
  }

  return backgroundStyles;
};

export const getWaitingRoomLeftContainerStyles = (
  imageUrl: string,
): React.CSSProperties => {
  const containerStyle: React.CSSProperties = {};
  if (imageUrl && !isDefaultImage(imageUrl)) {
    containerStyle.backgroundColor = '#fff';
    containerStyle.opacity = '.7';
    containerStyle.padding = '75px 20px 10px';
  }

  return containerStyle;
};
