import { InputLabel } from 'src/wick-ui/input/InputLabel';
import { ToggleSwitch } from 'src/wick-ui/input/ToggleSwitch';
import { ColorPicker } from '../color-picker/ColorPicker';
import { LpDropdown } from '@livepolls/ui-components/src/components/dropdown/LpDropdown';
import styles from './team-setting.module.css';
import { v4 as uuid } from 'uuid';
import { ITeam } from '@livepolls/ui-components/src/interfaces/team.interface';
import { useEffect, useRef, useState } from 'react';
import { TextInput } from 'src/wick-ui/input/TextInput';
import { LpToolTip } from '@livepolls/ui-components/src/components/tool-tip/LpToolTip';
import { DEFAULT_TEAM_NAMES } from 'src/constants/default-team-names.constants';
import { useOnClickOutside } from '@livepolls/ui-components/src/utils/hooks.util';
import { useTranslation } from 'react-i18next';
interface Props {
  teamSize: number;
  teams: ITeam[];
  anonymousResponses: boolean;
  manualAssignTeams: boolean;
  onChangeTeamSize: (value: number) => void;
  onChangeManualAssign: (value: boolean) => void;
  onChangeTeams: (cb: (v: ITeam[]) => ITeam[]) => void;
}

const TEAM_SIZES = [2, 3, 4];
const DEFAULT_TEAM_SIZE = 2;
const DEFAULT_TEAM_COLORS = ['#E53251', '#00E678', '#F5A300', '#00B4FF'];

const createTeam = (teamNum: number): ITeam => {
  return {
    id: uuid(),
    color: DEFAULT_TEAM_COLORS[teamNum - 1],
    name: '',
  };
};

export const TeamSetting = ({
  teamSize,
  teams,
  manualAssignTeams,
  anonymousResponses,
  onChangeTeamSize,
  onChangeManualAssign,
  onChangeTeams,
}: Props) => {
  const { t } = useTranslation();

  const teamColorPickerRef = useRef<HTMLDivElement | null>(null);

  const [selectedColorPickerIndex, setSelectedColorPickerIndex] = useState<
    number | null
  >(null);

  const toolTipTextOnHover = anonymousResponses
    ? t('manuallyAssignTeamsDisabledText')
    : t('manuallyAssignTeamsDesc');

  useEffect(() => {
    onChangeTeamSize(DEFAULT_TEAM_SIZE);
    onChangeTeams(_ => [createTeam(1), createTeam(2)]);
  }, [onChangeTeamSize, onChangeTeams]);

  useEffect(() => {
    if (anonymousResponses) {
      onChangeManualAssign(false);
    }
  }, [anonymousResponses, onChangeManualAssign]);

  const updateTeam = (updatedTeam: ITeam, index: number) => {
    onChangeTeams(oldTeams => {
      return [
        ...oldTeams.slice(0, index),
        updatedTeam,
        ...oldTeams.slice(index + 1),
      ];
    });
  };

  const handleChangeTeamName = (newName: string, index: number) => {
    const teamToUpdate = teams[index];
    const updatedTeam = { ...teamToUpdate, name: newName };
    updateTeam(updatedTeam, index);
  };

  const handleChangeTeamColor = (newColor: string, index: number) => {
    const teamToUpdate = teams[index];
    const updatedTeam = { ...teamToUpdate, color: newColor };
    updateTeam(updatedTeam, index);
  };

  const handleChangeTeamSize = (newTeamSize: number) => {
    onChangeTeamSize(newTeamSize);
    const previousTeamSize = teams.length;
    if (previousTeamSize < newTeamSize) {
      const newTeams = [] as ITeam[];
      for (let i = previousTeamSize + 1; i <= newTeamSize; i++) {
        newTeams.push(createTeam(i));
      }
      onChangeTeams(oldTeams => [...oldTeams, ...newTeams]);
    } else if (previousTeamSize > newTeamSize) {
      onChangeTeams(oldTeams => [...oldTeams.slice(0, newTeamSize)]);
    }
  };

  useOnClickOutside(teamColorPickerRef, teamColorPickerRef, () => {
    // remove the selected color picker border
    setSelectedColorPickerIndex(-1);
  });

  return (
    <>
      <div className={styles.teamSettingsContainer}>
        <div className={styles.teamSettingsSectionOne}>
          <InputLabel text={t('numOfTeams')} fullWidth={false}>
            <LpDropdown text={`${teamSize}`}>
              <ul>
                {TEAM_SIZES.map(teamSize => (
                  <li
                    key={teamSize}
                    onClick={() => handleChangeTeamSize(teamSize)}
                    className={styles.listItem}
                  >
                    {teamSize}
                  </li>
                ))}
              </ul>
            </LpDropdown>
          </InputLabel>

          <InputLabel text={t('manuallyAssignTeams')} fullWidth={false}>
            <LpToolTip textOnHover={toolTipTextOnHover}>
              <ToggleSwitch
                onChange={onChangeManualAssign}
                className={styles.toggleSwitchContainer}
                initialValue={manualAssignTeams}
                disable={anonymousResponses}
              />
            </LpToolTip>
          </InputLabel>
        </div>
        <div className={styles.teamSettingsSectionTwo}>
          <InputLabel text={t('teamNames')} markRequired={true}>
            {teams.map((_, index) => (
              <div key={index} className={styles.teamNameContainer}>
                <TextInput
                  placeholder={DEFAULT_TEAM_NAMES[index]}
                  onChange={newName => handleChangeTeamName(newName, index)}
                />
              </div>
            ))}
          </InputLabel>
        </div>
        <div>
          <InputLabel text={t('teamColor')}>
            {teams.map((team, index) => (
              <div
                key={index}
                className={styles.teamColorPickerContainer}
                style={
                  index === selectedColorPickerIndex
                    ? { borderColor: team.color }
                    : {}
                }
              >
                <div
                  className={styles.teamColorPickerWrapper}
                  style={{ backgroundColor: team.color }}
                  onClick={() => setSelectedColorPickerIndex(index)}
                  ref={teamColorPickerRef}
                >
                  <ColorPicker
                    colorInHex={team.color}
                    onChange={newColor =>
                      handleChangeTeamColor(newColor, index)
                    }
                    customClass={styles.customColorPicker}
                  />
                </div>
              </div>
            ))}
          </InputLabel>
        </div>
      </div>
    </>
  );
};
