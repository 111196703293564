import React from 'react';
import styles from './ColorPicker.module.css';

interface Props {
  colorInHex: string;
  onChange: (selectedColorInHex: string) => void;
  customClass?: string;
}

export const ColorPicker = ({ colorInHex, onChange, customClass }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  return (
    <input
      type="color"
      className={`${styles.colorPicker} ${customClass || ''}`}
      value={colorInHex}
      data-testid="color-picker-input"
      onChange={event => {
        handleChange(event);
      }}
    />
  );
};
