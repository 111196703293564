import React from 'react';
import styles from './LpNewToolTip.module.css';
import { ToolTipMarkerPositionEnum } from '../../enums/tooltip-marker-position.enum';

interface Props {
  children: any;
  textOnHover: string;
  showToolTip: boolean;
  toolTipTextCustomClass?: string;
  toolTipMarkerPosition?: ToolTipMarkerPositionEnum;
}
export const LpNewToolTip = ({
  children,
  textOnHover,
  toolTipTextCustomClass,
  showToolTip,
  toolTipMarkerPosition,
}: Props) => {
  const toolTipMarkerPositionClass =
    toolTipMarkerPosition != null &&
    toolTipMarkerPosition === ToolTipMarkerPositionEnum.RIGHT
      ? styles.markerRight
      : styles.markerLeft;

  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
      {showToolTip && (
        <div
          className={
            styles.toolTipText +
            ' ' +
            toolTipMarkerPositionClass +
            ' ' +
            toolTipTextCustomClass
          }
          data-testid="textToDisplay"
        >
          {textOnHover}
        </div>
      )}
    </div>
  );
};
